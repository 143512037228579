@import "variables";

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0px;
    height: 100vh;
}

a {
    text-decoration: none;
}

// ???????
.nice {
    animation: niceee 2s normal cubic-bezier(.68, -0.55, .27, 1.55);
}

@keyframes niceee {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

// ???????

// Tags
.dx-tag-content {
    border-radius: none;
}

// labels
.dx-texteditor-label {
    margin-top: -5px !important;
}

// Google autocomplete
.pac-container.pac-logo {
    z-index: 9999;
}

// Input switch
.dx-switch-handle::before {
    background-color: $clr-gray;
}

.pointerCursor {
    cursor: pointer;
}

// Grid column visibility
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag .dx-popup-content {
    padding-top: 10px;
}

// Grid
.dx-row.dx-data-row.dx-column-lines.dx-state-hover,
.dx-row.dx-data-row.dx-column-lines.dx-selection {

    td,
    .dx-template-wrapper {
        background-color: $clr-accent !important;
        color: $clr-white !important;
    }
}

// Col center
.dx-data-row td {
    vertical-align: middle !important;
}

// Select padding
.dx-editor-underlined .dx-placeholder::before {
    padding-left: 5px !important;
}

// MDB loader
.ngx-loading-logo{
    width: 250px !important;
}

// TagBox
.dx-tag-content::before {
    content: none;
}

.capitalize-text::first-letter{
    text-transform: uppercase;
}

h1 {
    margin: 0;
}

.dx-menu-base .dx-icon{
    font-size: inherit;
}